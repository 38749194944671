import React from "react";

import { PageContainer, Main } from "@components/1-atoms/Layout";
import Type, { variants, sizes } from "@components/1-atoms/Type";
import Link from "@components/1-atoms/Link";

const IndexPage = () => (
  <PageContainer>
    <Main>
      <Type element="h1" variant={variants.DISPLAY} size={sizes.LARGE}>
        Mike Trischetta
      </Type>
      <Type element="p" variant={variants.DISPLAY} size={sizes.SMALL}>
        Web Software Development
      </Type>
      <Type element="p">
        <ul>
          <li>
            <Link href="mailto:mike@fullscoredev.com">Email</Link>
          </li>
          <li>
            <Link href="https://github.com/mtrischetta" isExternal>
              GitHub
            </Link>
          </li>
          <li>
            <Link href="https://www.linkedin.com/in/mtrischetta/" isExternal>
              LinkedIn
            </Link>
          </li>
        </ul>
      </Type>
    </Main>
  </PageContainer>
);

export default IndexPage;
