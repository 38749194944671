import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import * as classes from "./Link.module.scss";

const Link = ({ children, href, className, isExternal }) => (
  <a
    href={href}
    className={classNames(classes.root, className)}
    {...(isExternal && {
      rel: "noopener noreferrer nofollow",
    })}
  >
    {children}
  </a>
);

Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  className: PropTypes.string,
  isExternal: PropTypes.bool,
};

Link.defaultProps = {
  children: null,
  href: null,
  className: null,
  isExternal: false,
};

export default Link;
