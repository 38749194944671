import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import * as classes from "./Layout.module.scss";

export const Container = ({ children, element: Element, className }) => (
  <Element className={classNames(classes.container, className)}>
    {children}
  </Element>
);

Container.propTypes = {
  children: PropTypes.node,
  element: PropTypes.elementType,
  className: PropTypes.string,
};

Container.defaultProps = {
  children: null,
  element: "div",
  className: null,
};

export const PageContainer = ({ children }) => (
  <div className={classes.pageContainer}>
    <Container className={classes.inner}>{children}</Container>
  </div>
);

PageContainer.propTypes = {
  children: PropTypes.node,
};

PageContainer.defaultProps = {
  children: null,
};

export const Main = ({ children }) => (
  <Container element="main" className={classes.main}>
    {children}
  </Container>
);

Main.propTypes = {
  children: PropTypes.node,
};

Main.defaultProps = {
  children: null,
};

export default {
  Container,
  Main,
};
