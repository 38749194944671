import React from "react";
import PropTypes from "prop-types";
import { values } from "ramda";
import classNames from "classnames";

import * as classes from "./Type.module.scss";

export const variants = {
  BODY: "body",
  DISPLAY: "display",
};

export const sizes = {
  SMALL: "Sm",
  MEDIUM: "Md",
  LARGE: "Lg",
};

const Type = ({
  children,
  element: Element,
  variant,
  size,
  className: classNameProp,
  attributes,
}) => (
  <Element
    {...attributes}
    className={classNames(classNameProp, {
      [classes[`${variant}${size}`]]: !!variant && !!size,
    })}
  >
    {children}
  </Element>
);

Type.propTypes = {
  children: PropTypes.node,
  element: PropTypes.elementType,
  variant: PropTypes.oneOf(values(variants)),
  size: PropTypes.oneOf(values(sizes)),
  className: PropTypes.string,
  attributes: PropTypes.shape(),
};

Type.defaultProps = {
  children: null,
  element: "span",
  variant: variants.BODY,
  size: sizes.MEDIUM,
  className: null,
  attributes: {},
};

export default Type;
